<div *ngIf="state?.isLoading" class="loader-overlay">
  <div class="loader-container">
    <div class="loader-header">
      <span class="icon-title">
        <mat-icon>{{ state.icon || 'hourglass_empty' }}</mat-icon>
        <span>{{ state.title }}</span>
      </span>
    </div>
    <div class="loader-content">
      <div class="progress-container">
        <div *ngIf="state.message || state.percentComplete !== undefined"
             class="progress-status">
          <span class="message">{{ state.message }}</span>
          <span *ngIf="state.percentComplete !== undefined"
                class="percentage">
            {{ state.percentComplete.toFixed(0) }}%
          </span>
        </div>
        <mat-progress-bar
          [color]="color"
          [mode]="mode"
          [value]="state.percentComplete">
        </mat-progress-bar>
        <div *ngIf="state.current !== undefined && state.total !== undefined"
             class="progress-count">
          {{ state.current }} / {{ state.total }}
        </div>
      </div>
    </div>
  </div>
</div>
