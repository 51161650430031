import {PDFConfig, PDFTheme} from "./chat-export.models";

export const DEFAULT_PDF_THEME: PDFTheme = {
  colors: {
    primary: '#2C3E50',
    secondary: '#34495E',
    timestamp: '#7F8C8D',
    planned: '#2980B9',
    attachment: '#27AE60'
  },
  fonts: {
    primary: 'helvetica',
    secondary: 'helvetica'
  }
};

export const DEFAULT_PDF_CONFIG: PDFConfig = {
  margins: {
    top: 20,
    bottom: 20,
    left: 25,
    right: 25
  },
  fontSize: {
    header: 14,
    subHeader: 12,
    content: 11,
    timestamp: 9,
    attachment: 10
  },
  spacing: {
    afterHeader: 8,
    afterContent: 10,
    afterTimestamp: 6,
    afterAttachment: 5,
    betweenMessages: 15
  },
  theme: DEFAULT_PDF_THEME
};
