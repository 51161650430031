import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ThemePalette} from '@angular/material/core';
import {LoaderState} from './loader.model';
import {LoaderService} from "./loader.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {
  state?: LoaderState;
  private subscription: Subscription;
  color: ThemePalette = 'primary';
  // determinate: show progress bar, indeterminate: show spinner
  mode: 'determinate' | 'indeterminate' = 'determinate';

  constructor(private loaderService: LoaderService) {
    this.subscription = this.loaderService.state$.subscribe(
      state => {
        this.state = state;
        // if showProgress is false or percentComplete is 0, show spinner, otherwise show progress bar
        this.mode = !state.showProgress || state.percentComplete === 0
          ? 'indeterminate'
          : 'determinate';
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
