import {Injectable} from '@angular/core';
import {jsPDF} from 'jspdf';
import {EventDetails, MessageAttachment, MessageContent, PDFConfig} from "./chat-export.models";
import {DEFAULT_PDF_CONFIG} from "./pdf-config";
import {humanReadableFileSize} from "../../common/files";
import {LoaderService} from "../../common/loader-overlay/loader.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ChatExportService {
  private readonly config: PDFConfig = DEFAULT_PDF_CONFIG;

  constructor(
    private loaderService: LoaderService,
    private translate: TranslateService
  ) {
  }

  private createPDFDocument(): jsPDF {
    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.setFont(this.config.theme.fonts.primary);
    return pdf;
  }

  private getChatContainer(elementId: string): HTMLElement {
    const element = document.getElementById(elementId);
    if (!element) {
      throw new Error(`Chat container with ID '${elementId}' not found`);
    }
    return element.cloneNode(true) as HTMLElement;
  }

  private extractMessageContent(messageEl: Element): MessageContent {
    return {
      sender: this.extractSenderInfo(messageEl),
      content: this.extractMessageText(messageEl),
      timestamp: this.extractTimestamp(messageEl),
      attachments: this.extractAttachments(messageEl),
      format: this.extractMessageFormat(messageEl),
      isPlanned: messageEl.hasAttribute('data-planned'),
      eventDetails: this.extractEventDetails(messageEl),
    };
  }

  private extractSenderInfo(messageEl: Element): MessageContent['sender'] {
    const senderEl = messageEl.querySelector('.sender-info');
    if (!senderEl) return undefined;

    return {
      name: senderEl.getAttribute('data-name') || 'Unknown',
      avatar: senderEl.getAttribute('data-avatar') || undefined,
      role: senderEl.getAttribute('data-role') || undefined
    };
  }

  private extractMessageText(messageEl: Element): string {
    const contentEl = messageEl.querySelector('.message-content-wrapper');
    return contentEl?.textContent?.trim() || '';
  }

  private extractTimestamp(messageEl: Element): string {
    const timestampEl = messageEl.querySelector('.message-timestamp');
    return timestampEl?.textContent?.trim() || '';
  }

  private extractAttachments(messageEl: Element): MessageAttachment[] {
    const attachmentEls = messageEl.querySelectorAll('.attachment-badge');
    return Array.from(attachmentEls).map(att => ({
      name: att.getAttribute('data-name') || 'Attachment',
      type: att.getAttribute('data-type') || 'file',
      size: att.getAttribute('data-size') || '0 KB'
    }));
  }

  private extractMessageFormat(messageEl: Element): MessageContent['format'] {
    const format = messageEl.querySelector(".message-container").getAttribute('type');
    return (format as MessageContent['format']) || 'default';
  }

  private extractEventDetails(messageEl: Element): EventDetails | undefined {
    if (!messageEl.hasAttribute('data-planned')) return undefined;
    const eventEl = messageEl.querySelector('.event-data');
    if (!eventEl) return undefined;

    return {
      type: eventEl.getAttribute('data-eventType') || 'general',
      dueAt: eventEl.getAttribute('data-due-at') || undefined,
      duration: Number(eventEl.getAttribute('data-duration')) || undefined,
      isDone: eventEl.hasAttribute('data-done'),
    };
  }

  private async writeMessageToPDF(
    pdf: jsPDF,
    content: MessageContent,
    currentY: number
  ): Promise<number> {
    let yPosition = currentY;

    // Add header with sender info
    yPosition = this.writeSenderHeader(pdf, content, yPosition);

    // Add event details if it's a planned message and details are present
    if (content.isPlanned && content.eventDetails) {
      yPosition = this.writeEventDetails(pdf, content.eventDetails, yPosition);
    }

    // Add main message content
    if (content.content) {
      yPosition = this.writeMessageContent(pdf, content, yPosition);
    }

    // Add attachments if present
    if (content.attachments?.length) {
      yPosition = this.writeAttachments(pdf, content.attachments, yPosition);
    }

    // Add timestamp
    yPosition = this.writeTimestamp(pdf, content, yPosition);

    return yPosition + this.config.spacing.betweenMessages;
  }

  private writeSenderHeader(
    pdf: jsPDF,
    content: MessageContent,
    yPosition: number
  ): number {
    const {margins, fontSize} = this.config;

    if (content.sender?.name) {
      pdf.setFont(this.config.theme.fonts.primary, 'bold');
      pdf.setFontSize(fontSize.header);
      pdf.setTextColor(this.config.theme.colors.primary);

      pdf.text(content.sender.name, margins.left, yPosition);

      if (content.sender.role) {
        pdf.setFont(this.config.theme.fonts.secondary, 'normal');
        pdf.setFontSize(fontSize.subHeader);
        pdf.setTextColor(this.config.theme.colors.secondary);
        // on the right side of the page (right margin)
        const roleXPosition = pdf.internal.pageSize.getWidth() - margins.right - pdf.getTextWidth(content.sender.role);
        pdf.text(
          content.sender.role,
          roleXPosition,
          yPosition
        );
      }

      return yPosition + this.config.spacing.afterHeader;
    }

    return yPosition;
  }

  private writeMessageContent(
    pdf: jsPDF,
    content: MessageContent,
    yPosition: number
  ): number {
    const {margins, fontSize} = this.config;
    const maxWidth = pdf.internal.pageSize.getWidth() - margins.left - margins.right;

    pdf.setFont(this.config.theme.fonts.primary, 'normal');
    pdf.setFontSize(fontSize.content);
    pdf.setTextColor(this.config.theme.colors.primary);

    const lines = pdf.splitTextToSize(content.content || '', maxWidth);

    // Check if we need a new page (if content doesn't fit)
    if (yPosition + (lines.length * this.config.spacing.afterContent) >
      pdf.internal.pageSize.getHeight() - margins.bottom) {
      pdf.addPage();
      yPosition = margins.top;
    }

    pdf.text(lines, margins.left, yPosition);
    return yPosition + (lines.length * this.config.spacing.afterContent);
  }

  private writeEventDetails(
    pdf: jsPDF,
    eventDetails: EventDetails,
    yPosition: number
  ): number {
    const {margins, fontSize} = this.config;

    pdf.setFont(this.config.theme.fonts.secondary, 'bold');
    pdf.setFontSize(fontSize.subHeader);
    pdf.setTextColor(this.config.theme.colors.planned);

    // Event type and status
    const eventTypeText = eventDetails.type;
    const statusText = eventDetails.isDone ? "Done" : "Pending";

    pdf.text(`${eventTypeText} - ${statusText}`, margins.left, yPosition);
    yPosition += this.config.spacing.afterContent;

    // Due date and duration
    if (eventDetails.dueAt || eventDetails.duration) {
      pdf.setFont(this.config.theme.fonts.secondary, 'normal');
      pdf.setFontSize(fontSize.content);

      if (eventDetails.dueAt) {
        pdf.text(
          `Due at: ${eventDetails.dueAt}`,
          margins.left,
          yPosition
        );
        yPosition += this.config.spacing.afterContent;
      }

      if (eventDetails.duration) {
        pdf.text(
          `Duration: ${eventDetails.duration} min`,
          margins.left,
          yPosition
        );
        yPosition += this.config.spacing.afterContent;
      }
    }

    return yPosition;
  }

  private writeAttachments(
    pdf: jsPDF,
    attachments: MessageAttachment[],
    yPosition: number
  ): number {
    const {margins, fontSize} = this.config;

    pdf.setFont(this.config.theme.fonts.secondary, 'bold');
    pdf.setFontSize(fontSize.attachment);
    pdf.setTextColor(this.config.theme.colors.attachment);

    pdf.text(
      "Attachments:",
      margins.left,
      yPosition
    );
    yPosition += this.config.spacing.afterContent;

    pdf.setFont(this.config.theme.fonts.secondary, 'normal');
    attachments.forEach(att => {
      const attachmentText = `name ${att.name} - type ${att.type} - size ${humanReadableFileSize(Number(att.size))}`;
      pdf.text(attachmentText, margins.left + 5, yPosition);
      yPosition += this.config.spacing.afterAttachment;
    });

    return yPosition;
  }

  private writeTimestamp(
    pdf: jsPDF,
    content: MessageContent,
    yPosition: number
  ): number {
    if (!content.timestamp) return yPosition;

    const {margins, fontSize} = this.config;

    pdf.setFont(this.config.theme.fonts.secondary, 'italic');
    pdf.setFontSize(fontSize.timestamp);
    pdf.setTextColor(this.config.theme.colors.timestamp);

    let timestampText = content.timestamp;

    const textWidth = pdf.getTextWidth(timestampText);
    const xPosition = pdf.internal.pageSize.getWidth() - margins.right - textWidth;

    pdf.text(timestampText, xPosition, yPosition);
    return yPosition + this.config.spacing.afterTimestamp;
  }

  private async processMessages(
    messages: NodeListOf<Element>,
    pdf: jsPDF,
    startY: number = this.config.margins.top
  ): Promise<number> {
    let currentY = startY;

    for (const messageEl of Array.from(messages)) {
      try {
        const messageContent = this.extractMessageContent(messageEl);
        currentY = await this.writeMessageToPDF(pdf, messageContent, currentY);
      } catch (error) {
        console.error('Error processing message:', error);
      }
    }

    return currentY;
  }

  /**
   * Waits for chat content to load by checking periodically
   * it resolves when the chat content is loaded
   * it rejects if the content is not loaded within 5 seconds
   * note: just a simple implementation, can be improved, but is working without issues for now
   */
  private async waitForChatContent(containerId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const intervalId = setInterval(() => {
        const chatContainer = document.getElementById(containerId);
        if (chatContainer?.querySelector('app-chat-message')) {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          resolve();
        }
      }, 100);

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        resolve();
      }, 5000);
    });
  }

  private writeThreadHeader(
    pdf: jsPDF,
    threadTitle: string,
    startY: number = this.config.margins.top
  ): number {
    const {margins, fontSize} = this.config;

    pdf.setFont(this.config.theme.fonts.primary, 'bold');
    pdf.setFontSize(fontSize.header + 2);
    pdf.setTextColor(this.config.theme.colors.primary);

    pdf.text(threadTitle, margins.left, startY);

    // Add a horizontal line
    const lineY = startY + 5;
    pdf.setLineWidth(0.5);
    pdf.line(
      margins.left,
      lineY,
      pdf.internal.pageSize.getWidth() - margins.right,
      lineY
    );

    return startY + this.config.spacing.afterHeader + 5;
  }

  /**
   * Exports a single chat thread to PDF
   * @param elementId - The ID of the chat container element
   * @param threadTitle - Optional title for the chat thread
   */
  async exportToPDF(elementId: string, threadTitle?: string): Promise<jsPDF> {
    try {
      const clonedElement = this.getChatContainer(elementId);
      const pdf = this.createPDFDocument();

      let currentY = this.config.margins.top;

      if (threadTitle) {
        currentY = this.writeThreadHeader(pdf, threadTitle, currentY);
      }

      const messages = clonedElement.querySelectorAll('app-chat-message');
      await this.processMessages(messages, pdf, currentY);

      return pdf;
    } catch (error) {
      console.error('Error exporting chat to PDF:', error);
      throw new Error(`Failed to export chat: ${error.message}`);
    }
  }

  /**
   * Exports all chat threads to a single PDF
   * @param containerId - The ID of the chat container
   * @param options - Optional configuration for the export
   */
  async exportAllToPDF(
    containerId: string = "chat-container",
    options: {
      includeThreadTitles?: boolean;
      waitBetweenThreads?: number;
    } = {includeThreadTitles: true, waitBetweenThreads: 2000}
  ): Promise<jsPDF> {
    try {
      const nodes = Array.from(document.querySelectorAll('.thread-node'));
      const pdf = this.createPDFDocument();

      // Initialize the loader with PDF export configuration
      this.loaderService.start({
        title: 'Exporting Chat to PDF',
        icon: 'picture_as_pdf',
        showProgress: true,
        current: 0,
        total: nodes.length,
        percentComplete: 0,
        message: this.translate.instant('loader.export.generating_pdf')
      });

      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i] as HTMLElement;
        const threadTitle = node.getAttribute('data-thread-title') || `Thread ${i + 1}`;

        // Update progress for current thread
        this.loaderService.updateProgress(
          i + 1,
          nodes.length,
          this.translate.instant('loader.export.processing', {current: threadTitle})
        );

        // Click to load the thread content
        node.click();

        try {
          await this.waitForChatContent(containerId);

          // Wait briefly between threads to allow content to load (additional insurance that the content is loaded)
          if (options.waitBetweenThreads && i < nodes.length) {
            await new Promise(resolve =>
              setTimeout(resolve, options.waitBetweenThreads)
            );
          }

          if (i > 0) {
            pdf.addPage();
          }

          let currentY = this.config.margins.top;

          if (options.includeThreadTitles) {
            currentY = this.writeThreadHeader(pdf, threadTitle, currentY);
          }

          const clonedElement = this.getChatContainer(containerId);
          const messages = clonedElement.querySelectorAll('app-chat-message');
          await this.processMessages(messages, pdf, currentY);
        } catch (error) {
          console.error(`Error processing thread ${i + 1}:`, error);
          // Update loader with error state for this thread
          this.loaderService.update({
            message: `Error: ${threadTitle}: ${error.message}`
          });
          // Wait briefly to show error message
          await new Promise(resolve => setTimeout(resolve, 1500));
        }
      }

      // Update loader with completion message before stopping
      this.loaderService.update({
        percentComplete: 100,
        message: this.translate.instant('loader.export.completed'),
      });

      // Brief delay to show completion message
      await new Promise(resolve => setTimeout(resolve, 1000));

      this.loaderService.stop();
      return pdf;
    } catch (error) {
      console.error('Error exporting all chats to PDF:', error);

      // Update loader with error state
      this.loaderService.update({
        message: `Error: ${error.message}`,
        icon: 'error'
      });

      // Brief delay to show error message
      await new Promise(resolve => setTimeout(resolve, 2000));

      this.loaderService.stop();
      throw new Error(`Failed to export all chats: ${error.message}`);
    }
  }
}
