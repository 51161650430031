import {Injectable, ComponentRef, ApplicationRef, createComponent, EnvironmentInjector} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LoaderState} from "./loader.model";
import {LoaderComponent} from './loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderState = new BehaviorSubject<LoaderState>({
    isLoading: false
  });
  private componentRef: ComponentRef<LoaderComponent> | null = null;

  state$: Observable<LoaderState> = this.loaderState.asObservable();

  constructor(private appRef: ApplicationRef, private injector: EnvironmentInjector) {}

  private addLoaderToDom(): ComponentRef<LoaderComponent> {
    const componentRef = createComponent(LoaderComponent, {environmentInjector: this.injector});
    this.appRef.attachView(componentRef.hostView);

    const domElem = componentRef.location.nativeElement;
    document.body.appendChild(domElem);

    return componentRef;
  }

  start(options: Omit<LoaderState, 'isLoading'> = {}) {
    if (!this.componentRef) {
      this.componentRef = this.addLoaderToDom();
    }

    this.loaderState.next({
      isLoading: true,
      ...options
    });
  }

  update(state: Partial<LoaderState>) {
    this.loaderState.next({
      ...this.loaderState.value,
      ...state
    });
  }

  updateProgress(current: number, total: number, message?: string) {
    const percentComplete = (current / total) * 100;
    this.update({
      current,
      total,
      percentComplete,
      message
    });
  }

  stop() {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView); // Remove the component from the appRef
      this.componentRef.destroy(); // Destroy the component to remove the subscription
      this.componentRef = null; // Reset the componentRef
    }

    this.loaderState.next({
      isLoading: false
    });
  }
}
