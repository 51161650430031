import { Component, Inject, Optional } from '@angular/core';
import DataStore from 'advoprocess/lib/parser/data-store';
import { saveAs } from 'file-saver';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { PossibleIncludes, exportOptions, isUploadedFile } from 'advoprocess';
import { AuthService } from 'src/app/auth/auth.service';
import { ProcessService } from 'src/app/views/process/process.service';
import {ChatExportService} from "../../services/chat-export/chat.export.service";
import {LoaderService} from "../loader-overlay/loader.service";
import {TranslateService} from "@ngx-translate/core";

export interface DossierExportDialogData {
  service?: ProcessService;
  store?: DataStore;
}

interface DossierExportForm {
  format: FormControl<typeof exportOptions[number]>;
  includeUploadedDocuments: FormControl<boolean>;
  includeProtocol: FormControl<boolean>;
  exportName: FormControl<string>;
}

@Component({
  selector: 'app-dossier-export-dialog',
  templateUrl: './dossier-export-dialog.component.html',
  styleUrls: ['./dossier-export-dialog.component.scss'],
})
export class DossierExportDialogComponent {
  exportOptions = exportOptions;

  exportForm = new FormGroup<DossierExportForm>({
    format: new FormControl(this.exportOptions[0]),
    includeUploadedDocuments: new FormControl(true),
    includeProtocol: new FormControl(false),
    exportName: new FormControl(),
  });

  constructor(
    @Optional() public dialogRef: MatDialogRef<DossierExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DossierExportDialogData,
    private auth: AuthService,
    private translate: TranslateService,
    public chatExportService: ChatExportService,
    private loaderService: LoaderService
  ) {
    this.exportForm.get('format').valueChanges.subscribe(() => {
      this.exportForm
        .get('exportName')
        .setValue(
          this.exportForm
            .get('format')
            .value?.buildName(
              this.data?.service?.executionState?.sync_id,
              this.data?.service?.executionState?.name
            )
        );
    });
    this.exportForm
      .get('exportName')
      .setValue(
        this.exportForm
          .get('format')
          .value?.buildName(
            this.data?.service?.executionState?.sync_id,
            this.data?.service?.executionState?.name
          )
      );
  }

  private async generatePDFBlob(): Promise<Blob | null> {
    let pdfBlob = null;
    if (this.exportForm.get('includeProtocol')?.value) {
      // Switch to messages tab
      document.getElementById('messages')?.click();
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Generate PDF
      const pdf = await this.chatExportService.exportAllToPDF();
      pdfBlob = pdf.output('blob');

      // Switch back to data overview
      document.getElementById('data-overview')?.click();
    }
    return pdfBlob;
  }

  async export() {
    // get the target format, and return if it's not set
    const target = this.exportForm.get('format')?.value;
    if (!target) return;
    const name = this.exportForm.get('exportName').value;

    // Close the dialog first
    this.dialogRef.close();

    // Then start the loader
    this.loaderService.start({
      title: this.translate.instant('loader.export.title'),
      icon: 'folder',
      showProgress: true,
      message: this.translate.instant('loader.export.start')
    });

    try {
      // Update loader for PDF generation if needed
      if (this.exportForm.get('includeProtocol')?.value) {
        this.loaderService.update({
          message: this.translate.instant('loader.export.generating_pdf'),
          icon: 'picture_as_pdf'
        });
      }

      let pdfBlob = await this.generatePDFBlob();

      // Update loader for upload operation
      this.loaderService.update({
        isLoading: true, // because the exportAll func will call stop() inside
        message: this.translate.instant('loader.export.uploading'),
        icon: 'upload_file'
      });

      const result = await target.execute(
        this.data?.store,
        this.data?.service.stateId,
        this.auth.jwtToken$.value,
        name,
        this.getIncludes(),
        pdfBlob
      );

      if (isUploadedFile(result)) {
        this.loaderService.stop();
        return;
      }

      // Update loader for save operation
      this.loaderService.update({
        message: this.translate.instant('loader.export.saving'),
        icon: 'save'
      });

      saveAs(new Blob([result.content]), result.name);

      // Show completion message briefly before closing
      this.loaderService.update({
        message: this.translate.instant('loader.export.completed'),
        icon: 'check_circle',
        showProgress: false
      });

      // Keep completion message visible for 1 second
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.loaderService.stop();
    } catch (error) {
      console.error('Export failed:', error);

      // Show error in loader
      this.loaderService.update({
        message: this.translate.instant('loader.export.error'),
        icon: 'error',
        showProgress: false
      });

      // Keep error message visible for 2 seconds
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.loaderService.stop();
    }
  }

  private getIncludes(): PossibleIncludes {
    const includes: PossibleIncludes = [];
    if (this.exportForm.get('includeUploadedDocuments')?.value) {
      includes.push('includeUploadedDocuments');
    }
    return includes;
  }

  saveConfig() {
    this.dialogRef.close({
      exportName: this.exportForm.get('exportName').value,
      includes: this.getIncludes(),
    });
  }
}
